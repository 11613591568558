<template>
  <div class="clearfix mb-0 d-flex align-items-center">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-center">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25 mt-n3"
        href="https://egarmarket.com"
        target="_blank"
      >Egar Market</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="d-inline-block pt-25 border-left mx-2" />

    <a
      class="btn btn-sm btn-dark"
      target="_blank"
      href="https://www.facebook.com/groups/237600904415774"
      style="background: #3b5998 !important;"
    >
      <feather-icon
        icon="FacebookIcon"
      />
      للتواصل والدعم الفني
    </a>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
